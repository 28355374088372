import React, { useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { dataBase } from "../firebaseConfig";
import ExcelJS from "exceljs";

const Test = () => {
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingJson, setLoadingJson] = useState(false);

  async function handleLoadProductsAndExport() {
    setLoadingExcel(true);

    // Cargar productos
    const itemsCollection = collection(dataBase, "items");
    const itemsData = await getDocs(itemsCollection);
    const productos = itemsData.docs.map((doc) => {
      console.log("gaste una lectura desde el front");
      return { id: doc.id, ...doc.data() };
    });

    // Exportar a Excel
    const excelData = productos.map((doc) => ({
      categoria: doc.categoria,
      titulo: doc.titulo,
      descripcion: doc.descripcion,
      precio: doc.precio,
      stock: doc.stock,
      estado: doc.estado,
      medidas: `${doc.medidas.ancho}/${doc.medidas.alto}/${doc.medidas.patilla}`,
    }));
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("react-export-table-to-excel");
    worksheet.columns = [
      { header: "Categoria", key: "categoria" },
      { header: "Titulo", key: "titulo" },
      { header: "Descripcion", key: "descripcion" },
      { header: "Precio", key: "precio" },
      { header: "Stock", key: "stock" },
      { header: "Estado", key: "estado" },
      { header: "Medidas", key: "medidas" },
    ];
    worksheet.addRows(excelData);
    const fileName = `recetados-optimarketok_${new Date().toLocaleDateString()}.xlsx`;
    workbook.xlsx.writeBuffer().then(async (buffer) => {
      try {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: fileName,
          types: [
            {
              description: "Archivos de Excel",
              accept: {
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
              },
            },
          ],
        });

        if (fileHandle) {
          const writable = await fileHandle.createWritable();
          await writable.write(buffer);
          await writable.close();
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingExcel(false);
    });
  }

  async function handleDownloadJsonAndExport() {
    setLoadingJson(true);

    // Cargar productos
    const itemsCollection = collection(dataBase, "items");
    const itemsData = await getDocs(itemsCollection);
    const productos = itemsData.docs.map((doc) => {
      console.log("gaste una lectura desde el front");
      return { id: doc.id, ...doc.data() };
    });

    // Exportar a JSON

    // Exportar a JSON con url y name cambiados de lugar
    const jsonData = productos
      .filter((doc) => doc.estado === "activo")
      .map((doc) => {
        const imagenes = doc.imagenes.map((imagen) => ({
          name: imagen.name,
          url: `/imagenes/${imagen.name}`,
        }));

        return {
          id: doc.id,
          global: doc.global,
          categoria: doc.categoria,
          titulo: doc.titulo,
          descripcion: doc.descripcion,
          precio: doc.precio,
          stock: doc.stock,
          estado: doc.estado,
          medidas: doc.medidas,
          imagenes: imagenes,
        };
      });

    const fileName = `items.json`;

    const json = JSON.stringify(jsonData);
    const blob = new Blob([json], { type: "application/json" });

    try {
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: fileName,
        types: [
          {
            description: "Archivo JSON",
            accept: {
              "application/json": [".json"],
            },
          },
        ],
      });

      if (fileHandle) {
        const writable = await fileHandle.createWritable();
        await writable.write(blob);
        await writable.close();
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingJson(false);
  }

  return (
    <>
      <div
        className="boton"
        onClick={handleLoadProductsAndExport}
        style={{ backgroundColor: loadingExcel ? "red" : "" }}
      >
        {loadingExcel ? "Exportando..." : "Exportar a Excel"}
      </div>
      <div
        className="boton"
        onClick={handleDownloadJsonAndExport}
        style={{ backgroundColor: loadingJson ? "red" : "" }}
      >
        {loadingJson ? "Exportando..." : "Copia de seguridad"}
      </div>
    </>
  );
};

export default Test;
